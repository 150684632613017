import { t, i18n } from "@utils/i18n";
import { MutationStatus } from "react-query";
import { getFormattedUserName, unitHasDelayedAvailability } from "./view";
import { GroupOption } from "@components/FormElements/AutocompleteInput/AutocompleteInput";
import {
  MyUnit,
  MessageRecipient,
  MimeType,
  GamificationSettings,
  LimitedTo,
  Section,
  CourseCategory,
  Course,
  AccessToken,
  Branch,
} from "types/entities";
import { GroupedOption, HighlithedText, QueryFilter, SelectOption } from "types/common";
import { YOUTUBE_VIMEO_URL_REGEX, ULR_WITH_PROTOCOL_REGEX } from "@constants/validation";
import { Tag } from "types/entities/Common";
import { FilesTag } from "types/entities/Files";
import { sortFormattedCourses } from "@views/Groups/helpers";
import { CourseLight } from "types/entities/Courses";

type SortOrder = "asc" | "desc";

export const getFileType = (type: MimeType): string => {
  switch (type) {
    // application mime-types
    case "application/x-sql":
      return "SQL";
    case "application/x-zip":
      return "ZIP";
    case "application/epub+zip":
      return "EPUB";
    case "application/vnd.ms-powerpoint":
      return "PPT";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "PPTX";
    case "application/msword":
      return "DOC";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "DOCX";
    case "application/vnd.ms-excel":
      return "XLS";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "XLSX";
    case "application/pdf":
      return "PDF";
    case "application/zip":
    case "application/x-zip-compressed":
    case "application/zip-compressed":
      return "ZIP";
    case "application/sql":
      return "SQL";
    case "application/x-gzip":
    case "application/gzip":
      return "GZIP";
    case "text/csv":
      return "CSV";
    case "text/plain":
      return "TXT";

    // Image mime-types
    case "image/gif":
      return "GIF";
    case "image/jpeg":
      return "JPEG";
    case "image/png":
      return "PNG";
    case "image/heic":
      return "HEIC";

    // Video mime-types
    case "video/avi":
    case "video/x-msvideo":
      return "AVI";
    case "video/mp4":
      return "MP4";
    case "video/webm":
      return "WEBM";
    case "video/ogg":
      return "OGG";
    case "video/mpeg":
      return "MPEG";
    case "video/3gpp":
      return "3GPP";
    case "video/quicktime":
      return "MOV";
    case "video/x-ms-wmv":
      return "WMV";
    case "video/x-flv":
      return "FLV";

    // Audio mime-types
    case "audio/aiff":
    case "audio/x-aiff":
      return "AIF / AIFF";
    case "audio/mpeg":
      return "MP3";
    case "audio/3gpp":
      return "3GPP";
    case "audio/aac":
      return "AAC";
    case "audio/ogg":
      return "OGG";
    case "audio/wav":
      return "WAV";
    case "audio/x-ms-wma":
    case "video/x-ms-wma":
      return "WMA";
    case "audio/webm":
      return "WEBM";
    case "audio/mp4":
      return "MP4";

    // Captions mime-types
    case "text/srt":
      return "SRT";
    case "text/vtt":
      return "VTT";

    default: {
      return "N/A";
    }
  }
};

export const getUnitIdToContinue = (units: MyUnit[]): number => {
  const firstIncompleteUnit = units.find((unit) => {
    const { progress, is_active } = unit;

    // Should not affect learners, only for preview
    if (!is_active) return false;

    // In case of a sequential-order course, the first unit without progress should be the one to continue
    if (!progress) return true;
    // If all units have progress, the first not_attempted/incomplete/failed/pending unit should be the one to continue
    return ["not_attempted", "incomplete", "failed", "pending"].includes(progress?.status ?? "");
  });

  if (firstIncompleteUnit) {
    return firstIncompleteUnit.id;
  }

  return units[0].id;
};

export const getFlatUnits = (sections: Section[]): MyUnit[] =>
  sections
    .filter((section) => section.units !== null && section.units.length > 0)
    .flatMap((section) => section.units) as MyUnit[];

export const getPrevAndNextUnits = (
  units: MyUnit[],
  currentUnitId: number,
): { prevUnit: MyUnit | null; nextUnit: MyUnit | null } => {
  const unitIds = units.map((unit) => unit.id);
  const currentUnitIdIndex = unitIds.indexOf(currentUnitId);
  const prevUnitIndex = currentUnitIdIndex - 1 >= 0 ? currentUnitIdIndex - 1 : null;
  const nextUnitIndex = currentUnitIdIndex + 1 < units.length ? currentUnitIdIndex + 1 : null;

  return {
    prevUnit: units[prevUnitIndex ?? -1] ?? null,
    nextUnit: units[nextUnitIndex ?? -1] ?? null,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const filterByMessageRecipients = ([_key, value]: [
  string,
  boolean | MessageRecipient[],
]): boolean => {
  if (Array.isArray(value) && value.length > 0) {
    return true;
  }

  if (!Array.isArray(value)) {
    return value;
  }

  return false;
};

export const recipientsToAutocompleteOptions = ([key, value]: [
  string,
  boolean | MessageRecipient[],
]): GroupOption => {
  switch (key) {
    case "system_administrators":
      return {
        label: i18n.t(`messages.recipient.${key}`),
        options: [
          {
            label: i18n.t(`messages.recipient.${key}`),
            value: "0",
            group: "",
            type: "system_administrators",
          },
        ],
      };
    case "all_users":
      return {
        label: i18n.t(`messages.recipient.${key}`),
        options: [
          {
            label: i18n.t(`messages.recipient.${key}`),
            value: "0",
            group: "",
            type: "all_users",
          },
        ],
      };
    default: {
      const options = (value as MessageRecipient[]).map((recipient) => ({
        label: recipient.surname
          ? getFormattedUserName({
              name: recipient.name,
              surname: recipient.surname,
              login: recipient.login,
            })
          : recipient.name,
        value: recipient.id.toString(),
        group: i18n.t(`messages.recipient.${key}`, { count: 1 }),
        type: recipient.type,
      }));

      return {
        label: i18n.t(`messages.recipient.${key}`, { count: 2 }),
        options,
      };
    }
  }
};

export const mapAudienceToAutocompleteOptions = ([, value]: [string, LimitedTo[]]): GroupOption => {
  const options = value.map((item) => ({
    label: item.name,
    value: item.id.toString(),
    group: item.type,
  }));

  return {
    label: i18n.t(`discussions.audience.${value[0]?.type}`),
    options,
  };
};

export const isLeaderBoardEnabled = (
  gamificationSettings: GamificationSettings | null,
): boolean => {
  if (!gamificationSettings?.enabled) return false;
  return Boolean(gamificationSettings?.leaderboard?.enabled);
};

export const getPopupParams = (height: number, width: number): string => {
  const top = height / 2 - 680 / 2;
  const left = width / 2 - 980 / 2;
  return `width=980,height=680,top=${top},left=${left},scrollbars=yes,resizable=yes,status=yes,toolbar=no,location=no,menubar=no`;
};

export const getHighlightedText = (str: string, searchStr: string): HighlithedText | string => {
  const upperStr = str?.toUpperCase();
  const upperSearchStr = searchStr?.toUpperCase();
  const index = upperStr.indexOf(upperSearchStr);

  if (index === -1) {
    return str;
  }

  return {
    firstPart: str.substring(0, index),
    highlithedPart: str.substring(index, index + searchStr.length),
    lastPart: str.substring(index + searchStr.length),
  };
};

export const capitalize = (str: string): string => {
  if (!str) return "";
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

//Gets all categories and returns all ids
export const getCategoriesIds = (categories: CourseCategory[]): string[] => {
  return categories.reduce((acc: string[], r: CourseCategory) => {
    acc.push(r.id.toString());
    if (r.children) {
      acc = acc.concat(getCategoriesIds(r.children));
    }

    return acc;
  }, []);
};

//Gets a single categoryNode and returns all child nodes
export const getCategoryNodeChildren = (category: CourseCategory): string[] => {
  if (category.children) {
    return category.children.reduce((acc: string[], r: CourseCategory) => {
      r.parent_id && acc.push(r.parent_id?.toString());
      acc.push(r.id.toString());
      if (r.children) {
        acc = acc.concat(getCategoryNodeChildren(r));
      }

      return acc;
    }, []);
  }
  return [];
};

//gets a node category and returns all parent categories
export const getParentCategoriesOfNode = (
  categories: CourseCategory[] | undefined,
  categoryNodeId: string,
  categoryNodeParents: string[] = [],
): string[] => {
  if (categories) {
    for (const node of categories) {
      if (node.id.toString() === categoryNodeId) return categoryNodeParents;

      const found = getParentCategoriesOfNode(
        node.children,
        categoryNodeId,
        categoryNodeParents.concat(node.id.toString()),
      );

      if (found && found?.length > 0) return found;
    }
  }
  return [];
};

export const getMediaErrorMsg = (error: string, type: "video" | "audio"): string => {
  switch (error) {
    case "permission_denied":
      return t("assignment.mediaRecordErrors.permissionDenied");
    case "no_specified_media_found":
      return type === "video"
        ? t("assignment.mediaRecordErrors.noMediaFoundVideo")
        : t("assignment.mediaRecordErrors.noMediaFoundAudio");
    case "media_in_use":
      return t("assignment.mediaRecordErrors.mediaInUse");
    case "":
      return "";
    default:
      return t("assignment.mediaRecordErrors.general");
  }
};

export const languageChange = async (lang: string): Promise<void> => {
  await i18n.changeLanguage(lang);

  // set "ltr" or "rtl" direction
  document.body.dir = i18n.dir();
};

export const getIncompleteUnits = (isSequentialCourse: boolean, units: MyUnit[]): MyUnit[] => {
  return units.filter(({ progress }) => {
    if (!isSequentialCourse) return false;
    if (!progress) return true;

    return ["not_attempted", "incomplete", "failed", "pending"].some(
      (status) => progress?.status === status,
    );
  });
};

export const isTocItemActive = (
  unit: MyUnit,
  isSequentialCourse: boolean,
  incompleteUnits: MyUnit[],
  isReadonly: boolean,
): boolean => {
  const isAvailable = !unitHasDelayedAvailability(unit.availability);
  if (isReadonly) return false;
  if (!isAvailable) return false;
  if (!isSequentialCourse) return true;

  // first incomplete unit should be accessible
  if (incompleteUnits[0]?.id === unit.id) return true;

  // check if the unit is incomplete
  return !incompleteUnits.includes(unit);
};

/**
 * Decodes a Base64-encoded string into its original string format.
 *
 * @param {string} encodedString - The Base64-encoded string to decode.
 * @returns {string} - The decoded string in its original format.
 *
 * Note: This method assumes the encoded string contains only ASCII characters.
 *       For UTF-8 encoded data, consider using an extended decoding approach.
 */
export const decodeBase64 = (encodedString: string): string => {
  return atob(encodedString);
};

// base64 token decoding
export const parseAccessToken = (token: string): AccessToken => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join(""),
  );

  const decodeToken = JSON.parse(jsonPayload);
  decodeToken.sub = JSON.parse(decodeToken.sub);

  return decodeToken;
};

export const getFetchingStatus = (statusArr: MutationStatus[]): MutationStatus => {
  if (statusArr.every((status) => status === "success")) return "success";
  if (statusArr.some((status) => status === "error")) return "error";
  if (statusArr.some((status) => status === "loading")) return "loading";

  return "idle";
};

export const disableRightClick = (elementId: string): void => {
  const player = document.getElementById(elementId);
  if (player) {
    player.oncontextmenu = function (e): void {
      e.preventDefault();
    };
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSelectOptionArrayValues = <T extends Record<string, any>>(
  input: T[],
  mapFunction: (item: T) => { value: string; label: string },
): SelectOption[] => {
  return input.map((item) => mapFunction(item));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T>(arr: T[], fn: (item: T) => any): Record<string, T[]> => {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
};

/**
 * The input array @var grouped must be the result of @function groupBy
 * @function mapFunction example:
 * (item) => ({
 *   value: item.id.toString(),
 *   label: item.name,
 * })
 **/
export const createGroupedOptionsForSelect = <T>(
  grouped: Record<string, T[]>,
  mapFunction: (item: T) => { value: string; label: string },
): GroupedOption[] => {
  return Object.keys(grouped).reduce<GroupedOption[]>((array, key) => {
    const selectOptions: SelectOption[] = grouped[key].map((item) => mapFunction(item));

    array.push({ label: key, options: selectOptions });

    return array;
  }, []);
};

export const getSelectValueFromGroupedOptions = (
  groupedOptions: GroupedOption[],
  value?: number | null,
): SelectOption[] => {
  return groupedOptions
    .flatMap((item) => item.options)
    .filter((option) => option.value === value?.toString());
};

export const applyQueryFilter = ({
  filters,
  filter,
}: {
  filters: QueryFilter[];
  filter: QueryFilter;
}): QueryFilter[] => {
  const { key, value, label } = filter;

  // We deep clone filters in order to avoid referencing them and return a new object so the original object will be unaltered
  const filtersClone: QueryFilter[] = JSON.parse(JSON.stringify(filters));

  const index = filtersClone.findIndex((filter) => filter.key === key);

  // remove filter
  if (!value) return filtersClone.filter((filter) => filter.key !== key);

  // add new filter
  if (index === -1) return filtersClone.concat(filter);

  // update existing filter
  filtersClone[index].value = value;
  filtersClone[index].label = label;

  return filtersClone;
};

export const generateRandomString = (stringLength: number): string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = "";
  for (let i = 0; i < stringLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
};

export const isValidUrl = (url: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(url);
};

export const isValidUrlWithProtocol = (url: string): boolean => {
  return !!ULR_WITH_PROTOCOL_REGEX.test(url);
};

export const isValidYoutubeOrVimeoUrl = (url: string): boolean => {
  return !!YOUTUBE_VIMEO_URL_REGEX.test(url);
};

export const tagsToOptions = (tags: Tag[] | FilesTag[]): SelectOption[] => {
  return tags.map(({ id, name }) => ({ value: id.toString(), label: name }));
};

export const optionsToTags = (options: SelectOption[]): Tag[] | FilesTag[] => {
  return options.map(({ value, label }, index) => {
    const val = Number(value);
    // use a negative number when tag has no value (create new tags) to have unique ids
    return { id: val ? val : -index, name: label };
  });
};

export const buildCoursesForGroupSelect = (courses: Course[]): GroupedOption[] => {
  // Courses grouped by category
  const coursesByCategory = groupBy(
    courses ?? [],
    ({ category }) => category?.name ?? t("courses.uncategorizedCourses"),
  );
  // Create the above grouped by category courses ready for a <Select /> as GroupedOption[]
  const groupedCourses = createGroupedOptionsForSelect(coursesByCategory, ({ id, name, code }) => ({
    value: id.toString(),
    label: code ? `${name} (${code})` : name,
  }));
  // Sort the above so 'Uncategorized Courses' will be last in the list
  return sortFormattedCourses(groupedCourses);
};

export const unitsToOptions = (units: MyUnit[]): SelectOption[] => {
  return units.map(({ id, name }) => ({ value: id.toString(), label: name }));
};

export const coursesLightToOptions = (courses: CourseLight[]): SelectOption[] => {
  return courses.map(({ id, name }) => ({ value: id.toString(), label: name }));
};

export const categoriesToOptions = (categories: CourseCategory[]): SelectOption[] => {
  return categories.map(({ id, name }) => ({ value: id.toString(), label: name }));
};

export const branchesToOptions = (branches: Branch[]): SelectOption[] => {
  return branches.map(({ id, name }) => ({ value: id.toString(), label: name }));
};

export const sortArrayOfObjects = <T>(array: T[], key: keyof T, order: SortOrder = "asc"): T[] => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return order === "asc" ? -1 : 1;
    } else if (a[key] > b[key]) {
      return order === "asc" ? 1 : -1;
    } else {
      return 0;
    }
  });
};
