import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import {
  BillingAddressPostData,
  CancellationSurveyPostData,
  CouponValidationRes,
  CurrentPlanRes,
  InvoiceInfoRes,
  InvoiceRes,
  PlanChargesData,
  PlanChargesRes,
  PlansRes,
  RecipientsPostData,
} from "./types";
import { AxiosResponse } from "axios";
import { marketoQueryParam } from "@constants/queryParameters";

export const postCancelSubscription = async (data: CancellationSurveyPostData): Promise<void> => {
  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.subscription.cancelSubscription,
    data,
  );

  return res.data;
};

export const postDowngradeToFree = async (data: CancellationSurveyPostData): Promise<void> => {
  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.subscription.downgradeToFree,
    data,
  );

  return res.data;
};

export const getInvoices = async (): Promise<InvoiceRes> => {
  const res = await HttpClient.get(ENDPOINTS.accountAndSettings.subscription.invoices);

  return res.data;
};

export const downloadInvoice = async (invoiceId: string): Promise<string> => {
  const res = await HttpClient.get(
    ENDPOINTS.accountAndSettings.subscription.downloadInvoice(invoiceId),
    {
      responseType: "arraybuffer",
      responseEncoding: "binary",
    },
  );

  return res.data;
};

export const getPlans = async (shouldTrackMarketo = false): Promise<PlansRes> => {
  const url = shouldTrackMarketo
    ? `${ENDPOINTS.accountAndSettings.subscription.plans}?${marketoQueryParam}`
    : ENDPOINTS.accountAndSettings.subscription.plans;
  const res = await HttpClient.get(url);

  return res.data;
};

export const updateBillingAddress = async (
  data: BillingAddressPostData,
): Promise<AxiosResponse> => {
  const res = await HttpClient.put(ENDPOINTS.accountAndSettings.subscription.billingAddress, data);

  return res;
};

export const updateCreditCard = async (data: { token: string }): Promise<void> => {
  await HttpClient.put(ENDPOINTS.accountAndSettings.subscription.updateCreditCard, data);
};

export const updateRecipients = async (data: RecipientsPostData): Promise<void> => {
  await HttpClient.post(ENDPOINTS.accountAndSettings.subscription.updateRecipients, data);
};

export const getCurrentPlan = async (): Promise<CurrentPlanRes> => {
  const res = await HttpClient.get(ENDPOINTS.accountAndSettings.subscription.currentPlan);

  return res.data;
};

export const putPurchasePlan = async (data: PlanChargesData): Promise<void> => {
  const res = await HttpClient.put(ENDPOINTS.accountAndSettings.subscription.currentPlan, data);
  return res.data;
};

export const getInvoiceInfo = async (invoiceId: string): Promise<InvoiceInfoRes> => {
  const res = await HttpClient.get(
    ENDPOINTS.accountAndSettings.subscription.previewInvoice(invoiceId),
  );
  return res.data;
};

export const reactivateSubscription = async (): Promise<void> => {
  const res = await HttpClient.post(
    ENDPOINTS.accountAndSettings.subscription.reactivateSubscription,
  );

  return res.data;
};

export const getPlanCharges = async (
  data: PlanChargesData,
  shouldTrackMarketo = false,
): Promise<PlanChargesRes> => {
  const url = shouldTrackMarketo
    ? `${ENDPOINTS.accountAndSettings.subscription.planPricingDetails}?${marketoQueryParam}`
    : ENDPOINTS.accountAndSettings.subscription.planPricingDetails;

  const res = await HttpClient.post(url, data);
  return res.data;
};

export const postCouponValidation = async (couponStr: string): Promise<CouponValidationRes> => {
  const res = await HttpClient.get(
    `${ENDPOINTS.accountAndSettings.subscription.couponValidation}${couponStr}`,
  );
  return res.data;
};
